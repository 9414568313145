import React from 'react';
import { ONBOARDING_STEPS } from '../../../utils/constants/constants';

const stepDetails = [
  {
    id: ONBOARDING_STEPS.CHILDREN,
    title: 'Add Children',
    description: 'Tell us about your children'
  },
  {
    id: ONBOARDING_STEPS.SUBSCRIPTION,
    title: 'Choose Plan',
    description: 'Select your subscription plan'
  },
  {
    id: ONBOARDING_STEPS.SCHEDULE,
    title: 'Set Schedule',
    description: 'Choose preferred lesson times'
  },
  {
    id: ONBOARDING_STEPS.PAYMENT,
    title: 'Payment Details',
    description: 'Set up your payment method'
  },
  {
    id: ONBOARDING_STEPS.REVIEW,
    title: 'Review',
    description: 'Review your selections'
  }
];

function OnboardingSidebar({ currentStep, onStepClick }) {
  return (
    <div className="w-64 min-h-screen bg-white border-r border-gray-200 p-4">
      <div className="space-y-4">
        {stepDetails.map((step) => (
          <button
            key={step.id}
            onClick={() => onStepClick(step.id)}
            className={`w-full text-left p-4 rounded-lg transition-colors ${
              currentStep === step.id
                ? 'bg-violet-50 border-violet-500'
                : 'hover:bg-gray-50'
            }`}
          >
            <h3 className={`font-medium ${
              currentStep === step.id ? 'text-violet-700' : 'text-gray-900'
            }`}>
              {step.title}
            </h3>
            <p className={`text-sm ${
              currentStep === step.id ? 'text-violet-600' : 'text-gray-500'
            }`}>
              {step.description}
            </p>
          </button>
        ))}
      </div>
    </div>
  );
}

export default OnboardingSidebar;