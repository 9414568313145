import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter
} from '@/components/shadcn/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";
import { Button } from '@/components/shadcn/button';

function ChildrenCount({ onComplete, childCount, onChildCountChange }) {
  const stringChildCount = childCount?.toString() || '';

  const handleContinue = () => {
    if (stringChildCount) {
      onComplete({
        numberOfChildren : parseInt(stringChildCount)
        //details: []
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh]">
      <Card className="w-[600px]">
        <CardHeader className="space-y-6">
          <div>
            <CardTitle className="text-3xl mb-2">Welcome to Your Learning Journey</CardTitle>
            <CardDescription className="text-lg">
              Let's start by setting up tuition for your children
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-8">
            <label className="text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              How many children do you want tuition for?
            </label>
            <Select
              value={stringChildCount}
              onValueChange={(value) => onChildCountChange(parseInt(value))}
            >
              <SelectTrigger className="w-full text-lg py-6">
                <SelectValue placeholder="Select number of children" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1">1 Child</SelectItem>
                <SelectItem value="2">2 Children</SelectItem>
                <SelectItem value="3">3 Children</SelectItem>
                <SelectItem value="4">4 Children</SelectItem>
                {/*<SelectItem value="5">5 Children</SelectItem>*/}
              </SelectContent>
            </Select>
          </div>
        </CardContent>
        <CardFooter className="mt-8">
          <Button 
            onClick={handleContinue}
            disabled={!stringChildCount}
            className="w-full py-6 text-lg bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Continue
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ChildrenCount;