import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/shadcn/card';
import { Button } from '@/components/shadcn/button';
import { Alert, AlertDescription } from "@/components/shadcn/alert";
import { get, post } from '../../../utils/api';
import { useAuth } from '../../../contexts/AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentDetails({ onComplete, subscriptionPlan, paymentDetails, handleContinue }) {
  const [productDetails, setProductDetails] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [isStepCompleted, setIsStepCompleted] = useState(false);
  const [existingPaymentDetails, setExistingPaymentDetails] = useState(false);
  const { user } = useAuth();

  const initializePayment = async () => {
    try {
      const { clientSecret } = await post('/payments/initialize-payment', {
        userId: user._id,
        name: user.name,
        email: user.email,
      }, true);
      setClientSecret(clientSecret);
    } catch (error) {
      console.error('Error initializing payment:', error);
    }
  };

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        // Get product details
        const product = await get(`/payments/product-details/${subscriptionPlan}`, true);
        setProductDetails(product);

        //console.log('paymentDetails', paymentDetails);

        // Check if we already have payment details
        if (paymentDetails?.paymentMethodId) {
          setIsStepCompleted(true);
          setExistingPaymentDetails(true);
          
        } else {
          await initializePayment();
        }
      } catch (error) {
        console.error('Error initializing payment:', error);
      }
    };

    initializeComponent();
  }, [subscriptionPlan, user, paymentDetails]);

  const handleChangePaymentMethod = async () => {
    setIsStepCompleted(false);
    await initializePayment();
  };

  const handleUsePreviousPaymentMethod = () => {
    setIsStepCompleted(true);
  };

  if (!productDetails) return <div>Loading...</div>;

  // Show existing payment method if it exists
  if (isStepCompleted && paymentDetails) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[80vh] p-6">
        <Card className="w-full max-w-2xl">
          <CardHeader>
            <CardTitle>Payment Method Saved</CardTitle>
            <CardDescription>
              You've already added your payment details
            </CardDescription>
          </CardHeader>
          <CardContent>
            {/* Saved Card Details */}
            <div className="bg-green-50 p-4 rounded-lg mb-6">
              <div className="flex items-center space-x-2">
                <div className="text-green-600">✓</div>
                <div>
                  <p className="font-medium">
                    {paymentDetails.brand?.toUpperCase()} •••• {paymentDetails.cardDetails.last4}
                  </p>
                  <p className="text-sm text-gray-600">
                    Expires {paymentDetails.cardDetails.expiryMonth}/{paymentDetails.cardDetails.expiryYear}
                  </p>
                </div>
              </div>
            </div>

            {/* Plan Details */}
            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
              <h3 className="font-medium">Plan Details</h3>
              <p className="text-2xl font-bold">£{productDetails.price}/month</p>
              <ul className="mt-2 text-sm text-gray-600">
                <li>• 7-day free trial</li>
                <li>• Cancel anytime during trial</li>
                <li>• First payment after trial ends</li>
              </ul>
            </div>

            <div className="flex space-x-4">
              <Button
                onClick={() => handleContinue()}
                className="flex-1"
              >
                Continue
              </Button>
              <Button
                onClick={handleChangePaymentMethod}
                variant="outline"
                className="flex-1"
              >
                Change Payment Method
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Show payment form if no payment method exists or user wants to change it
  if (!clientSecret) return <div>Loading payment form...</div>;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm 
        onComplete={onComplete} 
        productDetails={productDetails}
        onUsePreviousPaymentMethod={handleUsePreviousPaymentMethod}
        existingPaymentDetails={existingPaymentDetails}
      />
    </Elements>
  );
}

function PaymentForm({ onComplete, productDetails, onUsePreviousPaymentMethod, existingPaymentDetails }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    try {
      const { error: submitError, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/onboarding/confirmation`,
        },
        redirect: 'if_required',
      });

      if (submitError) {
        setError(submitError.message);
      } else if (setupIntent) {
        // Save payment method details and update onboarding progress
       
        // Get payment method details from Stripe
        const paymentMethodResponse = await get(
            `/payments/payment-method/${setupIntent.payment_method}`,
            true
          );


     /*   await post('/payments/save-payment-method', {
          userId: user._id,
          paymentMethodId: setupIntent.payment_method
        }, true);

        // Update onboarding progress
        await post(`/onboarding/${user._id}/progress`, {
          currentStep: 'PAYMENT',
          paymentData: {
            stripePaymentMethodId: setupIntent.payment_method,
            // You'll get last4 from the payment method details
          }
        }, true); 

        onComplete({
            paymentMethodId: setupIntent.payment_method,
            isCompleted: true
          }); */

        /* onComplete({
          setupIntentId: setupIntent.id,
          paymentMethodId: setupIntent.payment_method,
          isCompleted: true
        }); */

        onComplete({
            setupIntentId: setupIntent.id,
            paymentMethodId: setupIntent.payment_method,
            cardDetails: {
              last4: paymentMethodResponse.last4,
              brand: paymentMethodResponse.brand,
              expiryMonth: paymentMethodResponse.expiryMonth,
              expiryYear: paymentMethodResponse.expiryYear
            },
            isCompleted: true
          });
      }
    } catch (err) {
      setError('An unexpected error occurred');
    }
    setProcessing(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-6">
      <Card className="w-full max-w-2xl">
        <CardHeader>
          <CardTitle>Add Payment Method</CardTitle>
          <CardDescription>
            Enter your card details to set up your {productDetails.name} subscription
          </CardDescription>
        </CardHeader>
        <CardContent>
          {/* Plan Details */}
          <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-medium">Plan Details</h3>
            <p className="text-2xl font-bold">£{productDetails.price}/month</p>
            <ul className="mt-2 text-sm text-gray-600">
              <li>• 7-day free trial</li>
              <li>• Cancel anytime during trial</li>
              <li>• First payment after trial ends</li>
            </ul>
          </div>

          {/* Payment Form */}
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            
            {error && (
              <Alert variant="destructive" className="mt-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <div className="flex flex-col space-y-4 mt-6">
              <Button
                type="submit"
                disabled={!stripe || processing}
                className="w-full py-6 text-lg bg-indigo-600 hover:bg-indigo-700"
              >
                {processing ? 'Processing...' : 'Save Payment Method'}
              </Button>

              {existingPaymentDetails && (
              <Button
                type="button"
                variant="outline"
                onClick={onUsePreviousPaymentMethod}
                className="w-full py-6 text-lg bg-gray-400 hover:bg-gray-500"
              >
                Use Previously Saved Payment Method
              </Button>
              )}

            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default PaymentDetails;
