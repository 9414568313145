import { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { get, post } from '../utils/api';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./shadcn/dialog";
import { Button } from "./shadcn/button";
import { AddPaymentMethod } from './AddPaymentMethod';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export function UpdatePaymentMethod({ 
  isOpen, 
  onClose, 
  onSuccess, 
  customerId,
  defaultPaymentMethodId
}) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddNew, setShowAddNew] = useState(false);
  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(false);

  useEffect(() => {
    if (customerId && isOpen) {
      loadPaymentMethods();
    }
  }, [customerId, isOpen]);

  const loadPaymentMethods = async () => {
    try {
      setLoading(true);
      const methods = await get(`/payments/list-all-payment-methods/${customerId}`, true);
      setPaymentMethods(methods);
    } catch (err) {
      setError('Failed to load payment methods');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      setUpdatingPaymentMethod(true);
      await post('/payments/update-default-payment-method', {
        customerId,
        paymentMethodId
      }, true);
      
      // Call onSuccess to trigger parent component refresh
      onSuccess();
    } catch (err) {
      setError('Failed to update default payment method');
      console.error(err);
    } finally {
      setUpdatingPaymentMethod(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Payment Methods</DialogTitle>
          <DialogDescription>
            Your saved payment methods
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {/* Existing Payment Methods Section */}
          {loading ? (
            <div>Loading payment methods...</div>
          ) : error ? (
            <div className="text-red-600">{error}</div>
          ) : (
            <div className="space-y-4">
              {paymentMethods.map(method => (
                <div 
                  key={method.id}
                  className={`flex items-center justify-between p-4 border rounded-lg ${
                    method.id === defaultPaymentMethodId ? 'bg-gray-50 border-gray-300' : ''
                  }`}
                >
                  <div>
                    <div className="flex items-center gap-2">
                      <p className="font-medium">
                        {method.brand.toUpperCase()} •••• {method.last4}
                      </p>
                      {method.id === defaultPaymentMethodId && (
                        <span className="text-xs bg-green-100 text-green-700 px-2 py-1 rounded">
                          Current Payment Method
                        </span>
                      )}
                    </div>
                    <p className="text-sm text-gray-500">
                      Expires {method.expiryMonth}/{method.expiryYear}
                    </p>
                  </div>
                  {method.id !== defaultPaymentMethodId && (
                    <Button 
                      variant="outline" 
                      size="sm" 
                      className="border-black hover:bg-gray-100"
                      onClick={() => handleSetDefaultPaymentMethod(method.id)}
                      disabled={updatingPaymentMethod}
                    >
                      {updatingPaymentMethod ? 'Updating...' : 'Select as payment method'}
                    </Button>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Add New Payment Method Section */}
          {!showAddNew ? (
            <Button 
              className="w-full"
              variant=""
              onClick={() => setShowAddNew(true)}
            >
              Add New Payment Method
            </Button>
          ) : (
            <div className="border-t pt-6">
              <h3 className="font-medium mb-4">Add New Card</h3>
              <AddPaymentMethod 
                customerId={customerId}
                onSuccess={(newMethod) => {
                  setPaymentMethods([...paymentMethods, newMethod]);
                  setShowAddNew(false);
                }}
                onClose={() => setShowAddNew(false)}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}