import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from './shadcn/button';
import { Alert, AlertDescription } from "./shadcn/alert";
import { get, post } from '../utils/api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function AddPaymentMethodForm({ onSuccess, onCancel }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    try {
      const { error: submitError, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/settings/payment`,
        },
        redirect: 'if_required',
      });

      if (submitError) {
        setError(submitError.message);
      } else if (setupIntent) {
        // Get payment method details
        const paymentMethodResponse = await get(
          `/payments/payment-method/${setupIntent.payment_method}`,
          true
        );
        
        onSuccess(paymentMethodResponse);
      }
    } catch (err) {
      setError('An unexpected error occurred');
    }
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="min-h-[300px]">
        <PaymentElement />
      </div>
      
      {error && (
        <Alert variant="destructive" className="mt-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex space-x-4 mt-6">
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!stripe || processing}
          className="flex-1"
        >
          {processing ? 'Processing...' : 'Add Payment Method'}
        </Button>
      </div>
    </form>
  );
}

export function AddPaymentMethod({ onSuccess, onClose, customerId }) {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const initializePayment = async () => {
      try {
        const { clientSecret } = await post('/payments/setup-intent', {
          customerId
        }, true);
        setClientSecret(clientSecret);
      } catch (error) {
        console.error('Error initializing payment:', error);
      }
    };

    initializePayment();
  }, [customerId]);

  if (!clientSecret) return <div>Loading...</div>;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <AddPaymentMethodForm onSuccess={onSuccess} onCancel={onClose} />
    </Elements>
  );
}