import { useEffect, useState } from 'react';
import TutorSingleQuestionPage from './Quiz/TutorSingleQuestionPage/TutorSingleQuestionPage';
import ReactPlayer from 'react-player';

function TutorHomePageV2({ studentState }) {
    const [currentStep, setCurrentStep] = useState(null);
    const [latestLesson, setLatestLesson] = useState(null);

    useEffect(() => {
        setCurrentStep(studentState?.currentStep);
    }, [studentState?.currentStep]);

    useEffect(() => {
        setLatestLesson(studentState?.latestLesson);
    }, [studentState?.latestLesson]);

    return (
        <div className="relative flex flex-col h-full w-full">
            {/* Main content section - 75% of height */}
            {latestLesson?.steps[currentStep]?.Type?.includes('Question') ? (
                <div className="h-[75%] w-full p-2 bg-slate-300 shadow-lg border-2 border-black rounded-lg mb-2">
                    <TutorSingleQuestionPage 
                        quizQuestion={latestLesson?.steps[currentStep]?.quizQuestionId}
                        ButtonsAreClickable={false}
                        onAnswerSelect={() => {}}
                    />
                </div>
            ) : (
                <div className="h-[75%] w-full p-2 bg-slate-300 shadow-lg border-2 border-black rounded-lg mb-2 flex justify-center items-center">
                    <div className="w-full h-full flex items-center justify-center">
                        <ReactPlayer 
                            url={latestLesson?.steps[currentStep]?.animation}
                            controls={true}
                            width="100%"
                            height="100%"
                            style={{ maxHeight: '100%' }}
                        />
                    </div>
                </div>
            )}

            {/* Talking point section - 20% of height with scrolling */}
            <div className="h-[20%] w-full p-2 bg-slate-300 shadow-lg border-2 border-black rounded-lg overflow-y-auto">
                <p className="text-sm text-gray-700 whitespace-pre-wrap text-center">
                    {latestLesson?.steps[currentStep]?.TalkingPoint}
                </p>            
            </div>
        </div>
    );
}

export default TutorHomePageV2;