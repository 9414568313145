/*
  We'll add a 30-min expiry (exp) so rooms won't linger too long on your account.
  See other available options at https://docs.daily.co/reference#create-room
 */
import { get } from '../../utils/api';

  async function createRoom() {
    const exp = Math.round(Date.now() / 1000) + 60 * 30;
    const options = {
      properties: {
        //potentially add back in exp if I want calls to expire
      //  exp,
        enable_prejoin_ui: false, // TODO: remove me when robots are no longer needed
      },
    };
  
    /*
      This endpoint is using the proxy as outlined in netlify.toml.
      Comment this out if you want to use the local option below.
    */
  //  const response = await fetch(`${window.location.origin}/api/rooms`, {
  //    method: "POST",
  //    body: JSON.stringify(options),
  //  });
  
    /*
      Uncomment the request below to test the "create room" functionality locally.
      Don't forget to comment out the request above, too!
    */
     const response = await fetch('https://api.daily.co/v1/rooms/', {
       method: 'POST',
       body: JSON.stringify(options),
       headers: {
         'Content-Type': 'application/json',
         Authorization: 'Bearer ' + process.env.REACT_APP_DAILY_API_KEY,
       },
     });

     const roomData = await response.json();
  
    //return await response.json();
    return roomData;
  }

  async function getRoomChild(childId) {
    // Get the entire child object
    // console.log('childId', childId);
    const child = await get(`/children/${childId}`, true);

    // Then create the room
    /*const response = await fetch('https://api.daily.co/v1/rooms/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + process.env.REACT_APP_DAILY_API_KEY,
      },
    });

    const roomData = await response.json();*/
    
    // Return the allocated classroom from child data
    //console.log(child, child);
    return {
      child: child,
      url: 'https://novalearning.daily.co/'+ child.allocatedClassroom  // This will be used as the room URL
    };
  }

  
  

  // is it dangerous or problematic to make getRoomTutor async even though we're not making a call to the API?
  
  async function getRoomTutor(classroomName) {
    // Get the entire child object
    //console.log('classroom Name', classroomName);
    //const child = await get(`/children/${childId}`, true);

    // Then create the room
    /*const response = await fetch('https://api.daily.co/v1/rooms/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + process.env.REACT_APP_DAILY_API_KEY,
      },
    });

    const roomData = await response.json();*/
    
    // Return the allocated classroom from child data
    //console.log(child, child);
    return {
      //child: child,
      url: 'https://novalearning.daily.co/'+ classroomName  // This will be used as the room URL
    };
  }
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default { createRoom, getRoomChild, getRoomTutor };