import React, { useState, useEffect } from 'react';
import { get } from '../utils/api';
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from './shadcn/table';
import { Button } from './shadcn/button';

function TutorUpcomingLessons({ createCall, startHairCheck }) {
  const [lessons, setLessons] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
    // Refresh data every minute
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
    
  }, []);

  /*useEffect(() => {
    console.log('classrooms', classrooms);
  }, [classrooms]);*/

  const fetchData = async () => {
    try {
      setLoading(true);
      const [lessonsResponse, classroomsResponse] = await Promise.all([
        get('/lesson-schedules/upcoming-chronological'),

         // NOTE: IF A CHILD ID THAT IS IN THE LESSONSCHEDULE CHILDREN'S ARRAY FOR A PARTICULAR LESSON TIME DOES NOT EXIST IN THE CHILDREN COLLECTION, 
         // THEN THE WHOLE CHILD ID WILL BE DROPPED FROM THE API RESPONSE
         // AND YOU'LL GET A MISMATCH BETWEEN THE NUMBER OF STUDENTS FOR A LESSON TIME AND THE SUM OF THE STUDENTS THAT ARE ALLOCATED TO CLASSROOMS
         // THIS SCENARIO SHOULD THEORETICALLY NEVER HAPPY THOUGH BECAUSE THE CHILD ID SHOULD ALWAYS EXIST IN THE CHILDREN COLLECTION
        get('/classroom-allocation')
        
      ]);
      setLessons(lessonsResponse);
      setClassrooms(classroomsResponse);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  const canJoinLesson = (dayOfWeek, time) => {
    const now = new Date();
    const today = now.toLocaleDateString('en-GB', { weekday: 'long' }).toLowerCase();
    
    if (dayOfWeek !== today) return false;

    const [lessonHour, lessonMinute] = time.split(':').map(Number);
    const lessonTime = new Date();
    lessonTime.setHours(lessonHour, lessonMinute, 0, 0);

    const diffInMinutes = (lessonTime - now) / (1000 * 60);
    
    //console.log(diffInMinutes);
    // Change the comparison to handle negative numbers correctly
    return diffInMinutes >= -40 && diffInMinutes <= 10;
  };

  const formatDayAndTime = (dayOfWeek, time) => {
    const today = new Date().toLocaleDateString('en-GB', { weekday: 'long' }).toLowerCase();
    const tomorrow = new Date(Date.now() + 86400000).toLocaleDateString('en-GB', { weekday: 'long' }).toLowerCase();
    
    let dayDisplay = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
    if (dayOfWeek === today) dayDisplay = 'Today';
    if (dayOfWeek === tomorrow) dayDisplay = 'Tomorrow';

    const [hour, minute] = time.split(':');
    const timeString = new Date(2000, 0, 1, hour, minute)
      .toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit' });

    return `${dayDisplay} at ${timeString}`;
  };

  const handleJoinClassroom = (classroomName) => {
    //console.log('this is the classroom Name', classroomName);
    createCall(classroomName).then((url) => {
      startHairCheck(url);
    });
  };


  if (loading) return <div className="text-center p-6">Loading lessons...</div>;
  if (error) return <div className="text-red-500 text-center p-6">{error}</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Upcoming Lessons</h2>
      
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/3">Date & Time</TableHead>
            <TableHead className="w-1/4">Number of Students</TableHead>
            <TableHead className="w-5/12">Classrooms</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {lessons.length === 0 ? (
            <TableRow>
              <TableCell colSpan={3} className="text-center py-4">
                No upcoming lessons scheduled
              </TableCell>
            </TableRow>
          ) : (
            lessons.map((lesson) => (
              <TableRow key={`${lesson.dayOfWeek}-${lesson.time}`}>
                <TableCell className="w-1/3">
                  {formatDayAndTime(lesson.dayOfWeek, lesson.time)}
                </TableCell>
                <TableCell className="w-1/4">
                  {lesson.studentCount} student{lesson.studentCount !== 1 ? 's' : ''}
                </TableCell>
                <TableCell className="w-5/12">
                  {canJoinLesson(lesson.dayOfWeek, lesson.time) ? (
                    <div className="space-y-4">
                      {classrooms.map((classroom) => (
                        <div key={classroom._id} className="space-y-1">
                          <Button
                            onClick={() => handleJoinClassroom(classroom.classroomName)}
                            className="w-full bg-indigo-600 hover:bg-indigo-700"
                          >
                            Join {classroom.classroomName}
                          </Button>
                          <p className="text-sm text-gray-500 text-center">
                            {classroom.allocatedStudents.length} student{classroom.allocatedStudents.length !== 1 ? 's' : ''} allocated
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-gray-500">
                      You'll be able to join the allocated classrooms 10 minutes before the lesson starts
                    </p>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default TutorUpcomingLessons;