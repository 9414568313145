import React, { useState, useCallback, useEffect } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useThrottledDailyEvent,
  useLocalSessionId,
  useDaily,
  useMeetingSessionState
} from '@daily-co/daily-react';
import { useAuth } from '../../../contexts/AuthContext';
import { get } from '../../../utils/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';
import LearningSession from '../../LearningSession';
import TutorLearningSession from '../../TutorView/TutorLearningSession';
//import ProgressBar from '../ProgressBar/ProgressBar';

export default function Call({ tutorEmails }) {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  const [showLearningSession, setShowLearningSession] = useState(true);

  const  { data }  = useMeetingSessionState();

  const [studentLearningStates, setStudentLearningStates] = useState({});

  const [studentHearingStates, setStudentHearingStates] = useState({});


  const [tutorParticipant, setTutorParticipant] = useState(null);
  const [studentParticipants, setStudentParticipants] = useState([]);

  const { user } = useAuth();
  const callObject = useDaily();

  
  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list
   * of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, [getUserMediaError]),
  );

  /*useEffect(() => {
    console.log('Student participants state:', studentParticipants);
}, [studentParticipants]);*/

  //this state exists on the student side and is only being updated on the student side
  //via the app-message event
  const [localStudentCanHear, setLocalStudentCanHear] = useState(true);

  // Modify manageTrackSubscriptions to respect the hearing state
  const manageTrackSubscriptions = useCallback((participants) => {
    //console.log('manageTrackSubscriptions', participants);
    if (!callObject) return;

    Object.values(participants).forEach((participant) => {
      if (participant.local) return;

      if (tutorEmails.includes(user.email)) {
        // tutor subscribes to everyone's tracks
        //console.log('Tutor subscribing to participant:', participant.session_id);
        callObject.updateParticipant(participant.session_id, {
          setSubscribedTracks: {
            audio: true,
            video: true,
            screenVideo: true
          }
        });
      } else {
        // Students only subscribe to tutor's tracks
        if (tutorEmails.includes(participant.user_name)) {
          //console.log('Student subscribing to tutor with studentsCanHear:', localStudentCanHear);
          //console.log('Tutor session ID:', participant.session_id);
          callObject.updateParticipant(participant.session_id, {
            setSubscribedTracks: {
              audio: localStudentCanHear,
              video: true,
              screenVideo: true
            }
          });


          // Log the participant's tracks after update
          //console.log('Tutor tracks after update:', callObject.participants()[participant.session_id].tracks);
        } else {
          // Don't subscribe to other students' tracks
          callObject.updateParticipant(participant.session_id, {
            setSubscribedTracks: {
              audio: false,
              video: false,
              screenVideo: false
            }
          });
        }
      }
    });
  }, [callObject, user.email, localStudentCanHear]); // Added dependencies

  // THIS BELOW useEffect IS TO PROPERLY HANDLE SUBSCRIPTIONS TO USERS THAT WERE ALREADY IN THE CALL AT THE TIME OF JOINING
  useEffect(() => {
    if (callObject) {
      //console.log('New participant joining - handling initial setup');
      //console.log('Current user:', user.email);
      //console.log('Initial participants:', callObject.participants());
      
      handleParticipantUpdate();
    }
  }, [callObject]); // Run when callObject is first available

  //useCallback is used to memoize the callback function so that it doesn't get recreated on every render
  //useCallback is a react hook
  const handleParticipantUpdate = useCallback(() => {
    //console.log('handleParticipantUpdate');
    const participants = callObject.participants();

    const tutors = Object.values(participants)
      .filter(participant => tutorEmails.includes(participant.user_name));

    // Check for multiple tutors
    if (tutors.length > 1 && tutorEmails.includes(user.email)) {
      // Sort tutors by join time and find the most recent
      const sortedTutors = tutors.sort((a, b) => 
        new Date(b.joined_at) - new Date(a.joined_at)
      );
      
     /* console.log('Sorted tutors by join time:', sortedTutors.map(t => ({
        user_name: t.user_name,
        joined_at: t.joined_at
      })));*/

      const isNewestTutor = sortedTutors[0].user_name === user.email;
      
      //console.log('Current user:', user.email);
      //console.log('Is newest tutor?', isNewestTutor);

      if (isNewestTutor) {
        confirmAlert({
          title: 'Multiple Tutors Detected',
          message: 'There is already a tutor in this call. You will be kicked out.',
          buttons: [
            {
              label: 'OK',
              onClick: async () => {
                try {
                  await callObject.leave();
                } catch (error) {
                  console.error('Error leaving call:', error);
                }
              }
            }
          ],
          closeOnClickOutside: false,
          closeOnEscape: false
        });
      }
    }

    const others = Object.values(participants)
      .filter(participant => !tutorEmails.includes(participant.user_name));

    //console.log('tutors', tutors);
    //console.log('others', others);

     // Only set tutor participant if we found one
     if (tutors.length > 0) {
       setTutorParticipant(tutors[0]);
     } else {
      setTutorParticipant(null);
     }
     setStudentParticipants(others);

    if ( tutors.length > 0 && !tutorEmails.includes(user.email) ) {
      callObject.sendAppMessage({
        type: 'update-student-hearing-state',
        data: { 
                  canHear: localStudentCanHear,
                  studentSessionid : localSessionId
              }
      }, tutors[0].session_id);

    };

    // Set hearing state only for new students
   /* setStudentHearingStates(prev => {
      const newStates = { ...prev };
      others.forEach(student => {
        // Only set if this student doesn't already have a hearing state
        // I'm setting the hearing state to true for all new students. 
        // Note that in Tray.js, the tutor's mute all students' button is set so that it assumes that students start by being able to hear the tutor
        // Am I dealing with this initial logic in 2 places? Should I try to put it all in one place?
        
        if (newStates[student.session_id] === undefined) {
          newStates[student.session_id] = true;
        }
      });
      return newStates;
    });
    */

    // Manage track subscriptions
    manageTrackSubscriptions(participants);

  }, [callObject, manageTrackSubscriptions]);


  // see the Daily documentation about useThrottledDailyEvent.
  // it said the callback param has to be a memoized reference otherwise a console error
  // that's why I used useCallback above

  //IMPORTANT: having participant-updated as a dependency caused  the audio subscription on the tutee side to not update properly
  // was it due to having circular depenendencies  of updating the audio subscription?
  useThrottledDailyEvent(
    ['participant-joined', /*'participant-updated',*/ 'participant-left'],
    handleParticipantUpdate, []);




  /* This is for displaying remote participants: this includes other humans, but also screen shares. */
  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1 || screens.length < 1;



  // Handle incoming app messages
  useDailyEvent(
    'app-message',
    useCallback(({ data, fromId }) => {
      if (data.type === 'learning-state-update') {
        setStudentLearningStates(prev => ({
          ...prev,
          [data.data.studentSessionid]: data.data.state
        }));
      }
      if (data.type === 'update-hearing-state') {
        

        callObject.updateParticipant(fromId, {
          setSubscribedTracks: {
            audio: data.data.canHear,
            video: true,
            screenVideo: true
          }
        });

        setLocalStudentCanHear(data.data.canHear); // Update the state

        //console.log('Received hearing update:', data.data.canHear);

    // now send a message back to the tutor which confirms that the student's hearing state was updated 
    // and which updates the tutor's record of the student's hearing state
    callObject.sendAppMessage({
      type: 'update-student-hearing-state',
      data: { 
                canHear: data.data.canHear,
                studentSessionid : localSessionId
            }
    }, fromId);



        //handleParticipantUpdate();
      }

    if (data.type === 'update-student-hearing-state') {
      //console.log('Received student hearing update:', data);

      //console.log('Student participants:', studentParticipants);

     setStudentHearingStates(prev => ({
        ...prev,
        [data.data.studentSessionid]: data.data.canHear
      })); 

      //console.log('Final Value ofStudent hearing states:', studentHearingStates);

     // setLocalStudentCanHear(data.data.canHear);  Update the state
    }


    }, [ studentHearingStates, localStudentCanHear]) //do I need to add handleParticipantUpdate or anything else to the dependency array?
  ); 

  /*useEffect(() => {
    console.log('Student Learning States:', studentLearningStates);
  }, [studentLearningStates]);*/

  const toggleStudentHearing = useCallback((studentSessionId) => {
 

      //console.log('initial value of student hearing state:', studentHearingStates[studentSessionId]);

      const newState = !studentHearingStates[studentSessionId];
      
      // Send message to the specific student
      callObject.sendAppMessage({
        type: 'update-hearing-state',
        data: { canHear: newState }
      }, studentSessionId);

      /*return {
        ...prev,
        [studentSessionId]: newState
      };*/

      //console.log("this is the room", callObject.room());

   
  }, [callObject, studentHearingStates]);

  const renderCallScreen = () => (
    <div className="">
      {tutorEmails.includes(user.email) ? (
        // Tutor View
        <div className="tutor-grid">
          {/* Local tutor tile always first */}
          {localSessionId && (
            <Tile
              id={localSessionId}
              isLocal
              isAlone={isAlone}
            />
          )}

          {/* Student tiles or learning sessions */}
          {data?.LessonView ? (
            // Learning session view
            studentParticipants.map((participant) => (
              <div key={participant.session_id}>
                <TutorLearningSession 
                  studentId={participant.user_id}
                  isTeacherView={true}
                  studentState={studentLearningStates[participant.user_id]}
                  student={participant}
                  canHearTutor={studentHearingStates[participant.session_id]}
                  onHearingToggle={() => toggleStudentHearing(participant.session_id)}
                />
              </div>
            ))
          ) : (
            // Regular video tiles view
            studentParticipants.map((participant) => (
              <div key={participant.session_id}>
                <Tile 
                  key={participant.session_id} 
                  id={participant.session_id} 
                  canHearTutor={studentHearingStates[participant.session_id]}
                  onHearingToggle={() => toggleStudentHearing(participant.session_id)}
                />
              </div>
            ))
          )}

          {/* Screen shares */}
          {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))}
        </div>
      ) : (
        // Student View
        <>
          {!tutorEmails.includes(user.email) && (
            <>
              {data?.LessonView ? (
                <div className="learning-session-layout">
                  <div className="learning-session">
                    <LearningSession 
                     studentSessionid = {localSessionId}
                    />
                  </div>
                  { tutorParticipant && (
                  <div className="absolute top-0 right-0">
                      <Tile key={tutorParticipant.session_id} id={tutorParticipant.session_id} TutorWatchMode />
                  </div>
                  )} 
                </div>
              ) : (
                <div className="side-by-side-container">
                  <Tile 
                    id={localSessionId} 
                    isLocal={true} 
                    isLargeTile={true}
                  />
                  {tutorParticipant && (
                    <Tile 
                      key={tutorParticipant.session_id}
                      id={tutorParticipant.session_id} 
                      isLargeTile={true}
                      
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}