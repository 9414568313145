import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import QuestionPage from "../../components/Quiz/QuestionPage/QuestionPage";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { get, patch, put, del } from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import { getRandomMusicFile } from "../../utils/musicUtils";
import "./Quiz.css";

const QuizPage = ({ 
    quizSubmissionId: propQuizSubmissionId, 
    lessonId: propLessonId, 
    topicId: propTopicId, 
    onQuizComplete,
    preventNavigation,
    onStateChange 
}) => {
    const { topicId: routeTopicId, lessonId: routeLessonId } = useParams();
    const location = useLocation();
    const { user, loggedInChild } = useAuth();

    const [quizSubmission, setQuizSubmission] = useState(null);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [quizStarted, setQuizStarted] = useState(false);
    const [allQuestionAttempts, setAllQuestionAttempts] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [ButtonsAreClickable, setButtonsAreClickable] = useState(true);
    const [musicFile, setMusicFile] = useState(null);

    const actualQuizSubmissionId = preventNavigation 
        ? propQuizSubmissionId 
        : location.state?.quizSubmissionId;
    const actualTopicId = preventNavigation ? propTopicId : routeTopicId;
    const actualLessonId = preventNavigation ? propLessonId : routeLessonId;

    const quizFinishedMessage = preventNavigation ? false : true;

    useEffect(() => {
        const fetchQuizSubmission = async () => {
            try {
                //console.log('Fetching quiz submission with id:', actualQuizSubmissionId);
                const response = await get(`/quiz/quiz-submissions/${actualQuizSubmissionId}`);
                //console.log('Full API response:', response);
                
                if (!response) {
                    console.error('No data returned from API');
                    // Handle the case where no data is returned
                    return;
                }

                if (response.questionAttempts.length === 0) {
                    const updatedQuizSubmission = {
                        ...response,
                        QuizStatus: "Complete"
                    };
                    await patch(`/quiz/quiz-submissions/${response._id}`, updatedQuizSubmission);
                    setQuizSubmission(updatedQuizSubmission);

                    if (preventNavigation && onQuizComplete) {
                        onQuizComplete();
                    }
                    else  /*if (loggedInChild)*/ {

                    // Clear the current topic and delete ChildTopic entry
                   
                        try {
                            await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                            //console.log('Current topic cleared successfully');

                           // await del(`/childTopics/${loggedInChild._id}/${topicId}`, true);
                           await del(`/childTopics/${loggedInChild._id}/${actualTopicId}`, true);
                            //console.log('ChildTopic entry deleted successfully');
                        } catch (error) {
                            console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                        }
                    }
                } else {
                    setQuizSubmission(response);
                    setAllQuestionAttempts(response.questionAttempts);
                    setQuizQuestions(response.questionAttempts
                        .filter(attempt => attempt.QuestionStatus === "not attempted")
                        .map(attempt => attempt.LessonQuestionId)
                    );
                }
                setTotalQuestions(response.questionAttempts.length);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching quiz submission:', error);
                setLoading(false);
            }
        };
    
        if (actualQuizSubmissionId) {
            fetchQuizSubmission();
        }
    }, [actualQuizSubmissionId, loggedInChild, actualTopicId, preventNavigation, onQuizComplete]);

    const isQuizContinuation = quizQuestions.length < allQuestionAttempts.length;

    const handleStartQuiz = () => {
        setQuizStarted(true);
    };

    const handleAnswerSubmit = async (isCorrect, selectedAnswerId, boxSubmission) => {
        setButtonsAreClickable(false);
        const currentQuestionId = quizQuestions[currentQuestionIndex];
        const updatedQuestionAttempts = quizSubmission.questionAttempts.map(attempt => {
            if (attempt.LessonQuestionId === currentQuestionId) {
                return {
                    ...attempt,
                    QuestionStatus: isCorrect ? 'correct' : 'incorrect',
                    chosenAnswer: selectedAnswerId,
                      // Only include BoxTypeAnswers if boxSubmission is provided
                ...(boxSubmission && { BoxTypeAnswers: boxSubmission })
                };
            }
            return attempt;
        });

        if (currentQuestionIndex === quizQuestions.length - 1) {
            const updatedQuizSubmission = {
                ...quizSubmission,
                 QuizStatus: "Complete", 
                questionAttempts: updatedQuestionAttempts
            };

            setQuizSubmission(updatedQuizSubmission);
            

            await patch(`/quiz/quiz-submissions/${quizSubmission._id}`, updatedQuizSubmission);
    
            // Clear the current topic for the logged-in child

            if (preventNavigation && onQuizComplete) {
                onQuizComplete();
            }
            else /* if (loggedInChild)*/ {

                try {
                    await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                    //console.log('Current topic cleared successfully');

                    // Delete the ChildTopic entry
                   // await del(`/childTopics/${loggedInChild._id}/${topicId}`, true);
                   await del(`/childTopics/${loggedInChild._id}/${actualTopicId}`, true);
                    //console.log('ChildTopic entry deleted successfully');
                } catch (error) {
                    console.error('Error clearing current topic or deleting ChildTopic entry:', error);
                }
            }
    
            // Quiz finished
            // You can add navigation to a results page here
        } else {

            const updatedQuizSubmission = {
                ...quizSubmission,
                questionAttempts: updatedQuestionAttempts
            };

            setQuizSubmission(updatedQuizSubmission);

            await patch(`/quiz/quiz-submissions/${quizSubmission._id}`, updatedQuizSubmission);
        }

    };

    // commenting out music for now so it doesn't annoy me while I'm building the student classroom

 /*   useEffect(() => {
        let audio;
        if (quizStarted) {
            getRandomMusicFile().then(file => {
                setMusicFile(file);
                audio = new Audio(file);
                audio.loop = true;
                audio.play();
            });
        }
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [quizStarted]); */

    // remove the params and dependencies that i don't need at some point
    useEffect(() => {
        if (onStateChange) {
            onStateChange({
                quizSubmission,
                currentQuestionIndex,
                totalQuestions,
                isQuizMode: true,
                quizStarted,
                quizStatus: quizSubmission?.QuizStatus
            });
        }
    }, [
        currentQuestionIndex,
        totalQuestions,
        quizStarted,
        quizSubmission,
        onStateChange
    ]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="quiz-container">
            {!quizStarted ? (
                <div className="start-container">
                    <h2>
                        {isQuizContinuation 
                            ? 'Are you ready to continue the quiz?' 
                            : 'Are you ready to start the quiz?'}
                    </h2>
                    <button 
                        onClick={handleStartQuiz} 
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        {isQuizContinuation ? 'Continue Quiz' : 'Start Quiz'}
                    </button>
                </div>
            ) : (
                <>
                    {quizSubmission.QuizStatus === 'Complete' ? (
                        <div className="completion-message">
                            {quizFinishedMessage && totalQuestions === 0 && (
                                <h2>No questions to complete for now.</h2>
                            )}  
                                <h2>You have finished the lesson! Click on "Let's Learn!" at the top of the page to select a new lesson !</h2>
                            
                        </div>
                    ) : (
                        quizQuestions.length > 0 && (
                            <QuestionPage
                                allQuizQuestionsLength={totalQuestions}
                                outstandingQuestionsLength={quizQuestions.length}
                                setCurrentQuestionIndex={setCurrentQuestionIndex}
                                quizQuestion={quizQuestions[currentQuestionIndex]}
                                onAnswerSelect={handleAnswerSubmit}
                                ButtonsAreClickable={ButtonsAreClickable}
                                setButtonsAreClickable={setButtonsAreClickable}
                                //setQuizIsFinished = {setQuizIsFinished}
                            />
                        )
                    )}
                </>
            )}
        </div>
    );
};

export default QuizPage;
