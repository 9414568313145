import React, { useState, useEffect } from 'react';
import { get } from '../../../utils/api';
import { Button } from '../../shadcn/button';
import './HomeScreen.css';

export default function HomeScreen({createCallRoomTest, createCall, startHairCheck }) {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const response = await get('/classroom-allocation/daily-rooms');
      const formattedRooms = response.map(roomName => ({
        classroomName: roomName,
        _id: roomName
      }));
      setRooms(formattedRooms);
      setError(null);
    } catch (err) {
      console.error('Error fetching rooms:', err);
      setError('Failed to load rooms');
    } finally {
      setLoading(false);
    }
  };

  const handleJoinClassroom = (classroomName) => {
    //console.log('this is the classroom Name', classroomName);
    createCallRoomTest(classroomName).then((url) => {
      startHairCheck(url);
    });
  };

  if (loading) return <div className="text-center p-6">Loading rooms...</div>;
  if (error) return <div className="text-red-500 text-center p-6">{error}</div>;

  return (
    <div className="home-screen">
      <h1>Daily React custom video application</h1>
      
      <div className="my-8">
        <Button 
          onClick={createCall}
          className="w-full sm:w-auto bg-green-600 hover:bg-green-700"
          size="lg"
        >
          Create and join new room
        </Button>
      </div>

      <p className="mt-8">Or join an existing room:</p>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
        {rooms.map((room) => (
          <div key={room._id} className="flex flex-col items-center space-y-2">
            <Button
              onClick={() => handleJoinClassroom(room.classroomName)}
              className="w-full bg-indigo-600 hover:bg-indigo-700"
              size="lg"
            >
              Join {room.classroomName}
            </Button>
          </div>
        ))}
      </div>

      <p className="text-sm text-gray-500">Select "Allow" to use your camera and mic for this call if prompted</p>
    </div>
  );
}