import React, { useCallback, useEffect, useState } from 'react';
import {
  useDevices,
  useDaily,
  useDailyEvent,
  DailyVideo,
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
} from '../../shadcn/alert-dialog';

import { Alert, AlertTitle, AlertDescription } from '../../shadcn/alert';
import UserMediaError from '../UserMediaError/UserMediaError';
import { useAuth } from '../../../contexts/AuthContext';

import './HairCheck.css';

export default function HairCheck({ joinCall, cancelCall }) {

  const { user } = useAuth();
  const localSessionId = useLocalSessionId();
  const initialUsername = useParticipantProperty(localSessionId, 'user_name');
  const { currentCam, currentMic, currentSpeaker, microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  const callObject = useDaily();
  const [username, setUsername] = useState(initialUsername);
  const [displayName, setDisplayName] = useState('');

  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    //setUsername(initialUsername);
    setUsername(user.email);
  }, [initialUsername]);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const handleChange = (e) => {
    setUsername(e.target.value);
    callObject.setUserName(e.target.value);
  };

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  };

  const handleJoin = (e) => {
    e.preventDefault();
    if (!displayName.trim()) {
      setShowDialog(true);
      return;
    }
    joinCall(username.trim(), displayName.trim());
  };

  const updateMicrophone = (e) => {
    setMicrophone(e.target.value);
  };

  const updateSpeakers = (e) => {
    setSpeaker(e.target.value);
  };

  const updateCamera = (e) => {
    setCamera(e.target.value);
  };

  return getUserMediaError ? (
    <UserMediaError />
  ) : (
    <>
    <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Display Name Required</AlertDialogTitle>
        <AlertDialogDescription>
          Please enter your display name before joining the call. This is the name that other participants will see.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogAction onClick={() => setShowDialog(false)}>
          Okay
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>

    <form className="hair-check" onSubmit={handleJoin}>
      <h1>Setup your hardware</h1>
      

      {/* Video preview */}
      {localSessionId && <DailyVideo sessionId={localSessionId} mirror />}

      {/* Display Name */}
      <div>
        <label htmlFor="displayName">Display Name:</label>
        <input
          name="displayName"
          type="text"
          placeholder="Enter your display name"
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
          required
        />
      </div>

      {/* Username */}
      <div>
        <label htmlFor="username">Your email:</label>
        <input
          name="username"
          type="text"
          value={username || ''}
          readOnly
          disabled
        />
      </div>

      {/* Microphone select */}
      <div>
        <label htmlFor="micOptions">Microphone:</label>
        <select name="micOptions" id="micSelect" onChange={updateMicrophone} value={currentMic?.device?.deviceId}>
          {microphones.map((mic) => (
            <option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
              {mic.device.label}
            </option>
          ))}
        </select>
      </div>

      {/* Speakers select */}
      <div>
        <label htmlFor="speakersOptions">Speakers:</label>
        <select name="speakersOptions" id="speakersSelect" onChange={updateSpeakers} value={currentSpeaker?.device?.deviceId}>
          {speakers.map((speaker) => (
            <option key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
              {speaker.device.label}
            </option>
          ))}
        </select>
      </div>

      {/* Camera select */}
      <div>
        <label htmlFor="cameraOptions">Camera:</label>
        <select name="cameraOptions" id="cameraSelect" onChange={updateCamera} value={currentCam?.device?.deviceId}>
          {cameras.map((camera) => (
            <option key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
              {camera.device.label}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleJoin} type="submit">
        Join call
      </button>
      <button onClick={cancelCall} className="cancel-call" type="button">
        Back to start
      </button>
    </form>
    </>
  );
}