import React, { useCallback, useState, useEffect } from 'react';
import {
  useAppMessage,
  useAudioTrack,
  useDaily,
  useLocalSessionId,
  useScreenShare,
  useVideoTrack,
  useMeetingSessionState,
  useDailyEvent
} from '@daily-co/daily-react';

import MeetingInformation from '../MeetingInformation/MeetingInformation';
//import Chat from '../Chat/Chat';

import './Tray.css';
import {
  CameraOn,
  Leave,
  CameraOff,
  MicrophoneOff,
  MicrophoneOn,
  Screenshare,
  Info,
  //ChatIcon,
  //ChatHighlighted,
  //EarOn,
  //EarOff,
} from './Icons';
import { useAuth } from '../../../contexts/AuthContext';

import { Ear, EarOff, Presentation, SquareUser } from 'lucide-react';

export default function Tray({ tutorEmails, leaveCall }) {
  const callObject = useDaily();
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();
  const { user } = useAuth();

  // this state exists on the tutor side and is only being updated on the tutor side
  const [remoteStudentsCanHear, setRemoteStudentsCanHear] = useState(true);

  const [showMeetingInformation, setShowMeetingInformation] = useState(false);
  //const [showChat, setShowChat] = useState(false);
  //const [newChatMessage, setNewChatMessage] = useState(false);

  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const  { data }  = useMeetingSessionState();

  /*useEffect(() => {
    console.log("this is the meeting data", data);
  }, [data]);*/


 

  /* When a remote participant sends a message in the chat, we want to display a differently colored
   * chat icon in the Tray as a notification. By listening for the `"app-message"` event we'll know
   * when someone has sent a message. */
  
  /*useAppMessage({
    onAppMessage: useCallback(() => {
     
      if (!showChat) {
        setNewChatMessage(true);
      }
    }, [showChat])
  }); 
*/
  const toggleVideo = useCallback(() => {
    callObject.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  const toggleStudentsCanHear = useCallback(() => {
    if (!callObject) return;

    const newState = !remoteStudentsCanHear;
    setRemoteStudentsCanHear(newState);

    // Send message to all participants
    callObject.sendAppMessage({
      type: 'update-hearing-state',
      data: { canHear: newState }
    }, '*');
  }, [callObject, remoteStudentsCanHear]);

  const toggleLessonView = useCallback(() => {
    if (!callObject) return;

    // Get the current value, defaulting to false if undefined

    

    const currentValue = data?.LessonView ?? false;

    callObject.setMeetingSessionData({LessonView: !currentValue}, 'replace' );

    //console.log("this is the meeting data", data);



  }, [callObject, data]);

  /*useDailyEvent('meeting-session-state-updated', useCallback(({data}) => {
    console.log("this is the meeting data", data);
  }, [data, callObject]));*/


  const toggleScreenShare = () => isSharingScreen ? stopScreenShare() : startScreenShare();

  const toggleMeetingInformation = () => {
    setShowMeetingInformation(!showMeetingInformation);
  };

  /*
  const toggleChat = () => {
    setShowChat(!showChat);
    if (newChatMessage) {
      setNewChatMessage(!newChatMessage);
    }
  };
  */

  return (
    <div className="tray">
      {showMeetingInformation && <MeetingInformation />}
      {/*  The chat messages 'live' in the <Chat/> component's state. We can't just remove the component */}
      {/*  from the DOM when hiding the chat, because that would cause us to lose that state. So we're */}
      {/*  choosing a slightly different approach of toggling the chat: always render the component, but only */}
      {/*  render its HTML when showChat is set to true. */}

      {/*   We're also passing down the toggleChat() function to the component, so we can open and close the chat */}
      {/*   from the chat UI and not just the Tray. */}
      {/* <Chat showChat={showChat} toggleChat={toggleChat} /> */}
      <div className="tray-buttons-container">
        <div className="controls">
          <button onClick={toggleVideo} type="button">
            {mutedVideo ? <CameraOff /> : <CameraOn />}
            {mutedVideo ? 'Turn camera on' : 'Turn camera off'}
          </button>
          <button onClick={toggleAudio} type="button">
            {mutedAudio ? <MicrophoneOff /> : <MicrophoneOn />}
            {mutedAudio ? 'Unmute mic' : 'Mute mic'}
          </button>
          {tutorEmails.includes(user?.email) && (
            <>
            <button onClick={toggleStudentsCanHear} type="button">
              {remoteStudentsCanHear ? <Ear /> : <EarOff color="#f63135"/>}
              {remoteStudentsCanHear ? 'Mute students\' hearing' : 'Unmute students\' hearing'}
            </button>
            <button onClick={toggleLessonView} type="button">
                {data?.LessonView ? <SquareUser/>: <Presentation/>}
                {data?.LessonView ? 'Show Student' : 'Show Lesson'}
            </button>
            </>
          )}
        </div>
        <div className="actions">
          {/*<button onClick={toggleScreenShare} type="button">*/}
            {/*<Screenshare />*/}
            {/*isSharingScreen ? 'Stop sharing screen' : 'Share screen'*/}
          {/*</button>*/}
          {/*<button onClick={toggleMeetingInformation} type="button">*/}
            {/*<Info />*/}
            {/*{showMeetingInformation ? 'Hide info' : 'Show info'}*/}
          {/*</button>*/}
         
          {/*<button onClick={toggleChat} type="button"> */}
            {/* newChatMessage ? <ChatHighlighted /> : <ChatIcon /> */}
            {/* {showChat ? 'Hide chat' : 'Show chat'} */}
          {/*</button> */}
        </div> 
        <div className="leave">
          <button onClick={leaveCall} type="button">
            <Leave /> Leave call
          </button>
        </div>
      </div>
    </div>
  );
}