import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import OnboardingSidebar from './OnboardingProgress/OnboardingSidebar';
import StepIndicator from './OnboardingProgress/StepIndicator';
import ChildrenCount from './Steps/ChildrenCount';
import SubscriptionPlan from './Steps/SubscriptionPlan';
import ChildDetails from './Steps/ChildDetails';
import PaymentDetails from './Steps/PaymentDetails';
import Review from './Steps/Review';
import { get, post } from '../../utils/api';  // Import your API utilities
import { ONBOARDING_STEPS } from '../../utils/constants/constants';


/*export const ONBOARDING_STEPS = {
  CHILDREN: 'CHILDREN',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SCHEDULE: 'SCHEDULE',
  PAYMENT: 'PAYMENT',
  REVIEW: 'REVIEW'
};*/

function OnboardingFlow() {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(ONBOARDING_STEPS.CHILDREN);
  const [onboardingData, setOnboardingData] = useState({
    children: null,
    subscription: null,
    schedule: null,
    payment: null
  });

  // Children count is for step 1
  const [childCount, setChildCount] = useState(null);

  // Subscription plan is for step 2
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);

  // Child details is for step 3
  const [childDetails, setChildDetails] = useState(null);

  // Payment details is for step 4
  const [paymentDetails, setPaymentDetails] = useState(null);

  const navigate = useNavigate();

  const handleStepComplete = async (stepId, stepData) => {
    try {
      // Save the progress
      await post(`/onboarding/${user._id}/save`, {
        step: stepId,
        data: stepData
      }, true);

      // Update local state
      setOnboardingData(prev => ({
        ...prev,
        [stepId.toLowerCase()]: stepData
      }));

      // Fetch latest progress from server
      await fetchProgressAfterStepComplete();

            // Move to next step
            const steps = Object.values(ONBOARDING_STEPS);
            const currentIndex = steps.indexOf(stepId);
            if (currentIndex < steps.length - 1) {
              setCurrentStep(steps[currentIndex + 1]);
            }
      
    } catch (error) {
      console.error('Failed to save progress:', error);
    }
  };

  const moveToNextStep = (stepId) => {
    const steps = Object.values(ONBOARDING_STEPS);
    const currentIndex = steps.indexOf(stepId);
    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  // Move fetchProgress outside useEffect so it can be reused
  const fetchProgress = async () => {
    try {
      //console.log('Fetching onboarding progress for user:', user);
      const data = await get(`/onboarding/${user._id}/progress`, true);
      //console.log('Fetched onboarding progress:', data);
      if (data.currentStep) {
       
        setCurrentStep(data.currentStep);
        setChildCount(data.numberOfChildren);
        setSubscriptionPlan(data.subscriptionPlan);
        setChildDetails(data.childDetails);
        setPaymentDetails(data.paymentData);
        setOnboardingData(data.stepData);
        
        
      }
    } catch (error) {
      console.error('Failed to fetch onboarding progress:', error);
    }
  };

    // Difference between this one and fetchProgress is this one does not setCurrentStep
    const fetchProgressAfterStepComplete = async () => {
        try {
          //console.log('Fetching onboarding progress for user:', user);
          const data = await get(`/onboarding/${user._id}/progress`, true);
          //console.log('Fetched onboarding progress:', data);
          if (data.currentStep) {

            setChildCount(data.numberOfChildren);
            setSubscriptionPlan(data.subscriptionPlan);
            setChildDetails(data.childDetails);
            setPaymentDetails(data.paymentData);

            setOnboardingData(data.stepData);
            
          }
        } catch (error) {
          console.error('Failed to fetch onboarding progress:', error);
        }
      };

  useEffect(() => {
    if (user?._id) {
      fetchProgress();
    }
  }, [user?._id]);

  const handleEditSection = (step) => {
    if (step === 'COMPLETE') {
      // Handle completion
      handleStepComplete(ONBOARDING_STEPS.REVIEW, { isCompleted: true });
    } else {
      setCurrentStep(step);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <StepIndicator currentStep={currentStep} />
      
      <div className="flex">
        <OnboardingSidebar 
          currentStep={currentStep} 
          onStepClick={setCurrentStep}
        />
        
        <div className="flex-1">
          {currentStep === ONBOARDING_STEPS.CHILDREN && (
            <ChildrenCount 
              onComplete={(data) => handleStepComplete(ONBOARDING_STEPS.CHILDREN, data)}
              childCount={childCount}
              onChildCountChange={setChildCount}
            />
          )}
          {currentStep === ONBOARDING_STEPS.SUBSCRIPTION && (
            <SubscriptionPlan
              onComplete={(data) => handleStepComplete(ONBOARDING_STEPS.SUBSCRIPTION, data)}
              childCount={childCount}
              currentPlan={subscriptionPlan}
              childDetails={childDetails}
            />
          )}
          {currentStep === ONBOARDING_STEPS.SCHEDULE && (
            <ChildDetails
              onComplete={(data) => handleStepComplete(ONBOARDING_STEPS.SCHEDULE, data)}
              subscriptionPlan={subscriptionPlan}
              childDetails={childDetails}
            />
          )}
          {currentStep === ONBOARDING_STEPS.PAYMENT && (
            <PaymentDetails
              onComplete={(data) => handleStepComplete(ONBOARDING_STEPS.PAYMENT, data)}
              subscriptionPlan={subscriptionPlan}
              paymentDetails={paymentDetails}
              handleContinue={() => moveToNextStep(ONBOARDING_STEPS.PAYMENT)}
            />
          )}
          {currentStep === ONBOARDING_STEPS.REVIEW && (
            <Review
              subscriptionPlan={subscriptionPlan}
              childDetails={childDetails}
              paymentDetails={paymentDetails}
              onEditSection={handleEditSection}
            />
          )}
          {/* Other steps will go here */}
        </div>
      </div>
    </div>
  );
}

export default OnboardingFlow;