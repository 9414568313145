import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '@/components/shadcn/button';
import { Sparkles } from 'lucide-react';
import { FlickeringGrid } from '@/components/shadcn/flickering-grid';
import { Marquee } from "@/components/shadcn/marquee";
import { SparklesText } from "@/components/shadcn/sparkles-text";
import { cn } from "@/lib/utils";
import { MagicCard } from "@/components/shadcn/magic-card";
import { ArrowRight, GraduationCap, UserPlus, Calendar, MessageSquare, Check } from "lucide-react";
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent 
} from "@/components/shadcn/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/shadcn/accordion";

import heroImage from '../media/hero-images/hero-image-1-transparent.png';

const LandingPage = () => {
  const navigate = useNavigate();
  
  const keyPoints = [
    "Personalised Learning",
    "Expert Support",
    "Fun Interactive Lessons"
  ];

  const reviews = [
    {
      name: "Jodie C",
      role: "Parent",
      content: "I've never seen my son so engaged while learning! He absolutely loves doing his lessons with Nova",
      avatar: "/path-to-avatar.jpg"
    },
    {
      name: "Kehinde O",
      role: "Parent",
      content: "The lessons are really fun and engaging, my son has been learning so much!",
      avatar: "/path-to-avatar.jpg"
    },
    {
      name: "Jackie P",
      role: "Parent",
      content: "My daughter's confidence in maths has grown tremendously since we started using Nova, I've never seen her this excited about Maths!",
      avatar: "/path-to-avatar.jpg"
    },
    {
      name: "Sandra J",
      role: "Parent",
      content: "Learning with Nova has been a game changer for my son, he always looks forward to his classroom sessions!",
      avatar: "/path-to-avatar.jpg"
    },
    {
      name: "Alex S",
      role: "Parent",
      content: "Nova Learning has been great for our children! They love the lessons and the fun animations",
      avatar: "/path-to-avatar.jpg"
    },
    {
      name: "Levi M",
      role: "Parent",
      content: "My daughter loves the talk-to-Addy feature, it really keeps learning fun and engaging!",
      avatar: "/path-to-avatar.jpg"
    },
    // Add more reviews...
  ];

  // Split reviews into two arrays
  const firstRow = reviews.slice(0, Math.ceil(reviews.length / 2));
  const secondRow = reviews.slice(Math.ceil(reviews.length / 2));

  const ReviewCard = ({ name, role, content, avatar }) => {
    return (
      <figure
        className={cn(
          "relative w-[300px] cursor-pointer overflow-hidden rounded-xl border p-6 mx-4",
          "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
          "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]"
        )}
      >
        <div className="flex flex-row items-center gap-4">
          {/*<img 
            className="rounded-full w-12 h-12" 
            alt={name} 
            src={avatar} 
          />*/}
          <div className="flex flex-col">
            <figcaption className="text-sm font-medium dark:text-white">
              {name}
            </figcaption>
            <p className="text-xs font-medium text-gray-500 dark:text-white/40">
              {role}
            </p>
          </div>
        </div>
        <blockquote className="mt-4 text-sm text-gray-600">
          {content}
        </blockquote>
      </figure>
    );
  };

  return (
    <div className="min-h-screen bg-white/0 overflow-x-hidden">
      {/* Hero Section with contained FlickeringGrid */}
      <div className="relative h-fit">
        {/* Flickering Grid Background */}
        <div className="absolute -z-10 inset-0">
          <FlickeringGrid 
            squareSize={6}
            gridGap={6}
            flickerChance={0.3}
            color="rgb(245, 158, 11)"
            maxOpacity={0.2}
            className="w-full h-full"
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-12 w-full">
          {/* Content Grid */}
          <div className="grid lg:grid-cols-2 gap-12 items-center w-full">
            {/* Text Content - Add z-index to ensure it's above the hero image */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="relative space-y-8 min-h-[400px] z-10"
            >
              <h1 className="text-5xl md:text-7xl font-bold leading-tight">
                Making Maths 
                <span className="block text-transparent bg-clip-text bg-gradient-to-r from-amber-500 to-orange-500">
                  Simple & Fun
                </span>
                for Every Child
              </h1>

              {/* Key Points as Floating Cards */}
              <div className="flex flex-wrap gap-3">
                {keyPoints.map((point, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 + 0.3 }}
                    className="bg-white px-4 py-2 rounded-full shadow-md border border-amber-100 flex items-center gap-2"
                  >
                    <Sparkles className="h-4 w-4 text-amber-500" />
                    <span className="text-gray-700">{point}</span>
                  </motion.div>
                ))}
              </div>

              {/* CTA Button */}
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Button 
                  onClick={() => navigate('/register')}
                  className="bg-gradient-to-r from-amber-500 to-orange-500 text-white px-8 py-6 rounded-full text-lg font-medium hover:shadow-lg transition-all duration-300"
                >
                  Start Free Trial!
                </Button>
                <p className="mt-4 text-black text-base font-semibold bg-amber-100 px-4 py-2 rounded-lg inline-block outline outline-2 outline-black">
                  Try 2 classroom sessions for free!
                </p>
              </motion.div>
            </motion.div>

            {/* Hero Image - Ensure it stays below the text content on mobile */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1.3 }}
              transition={{ duration: 0.6 }}
              className="relative lg:z-0 -z-5"
            >
              <div className="aspect-square max-w-[400px] md:max-w-[800px] mx-auto pointer-events-none">
                <img
                  src={heroImage}
                  alt="Child learning on tablet"
                  className="w-full h-full object-contain"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Add this section after your hero section and before reviews */}
      <div id="how-it-works" className="py-10 bg-white/0">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-6">
            How It Works
          </h2>

          <div className="flex flex-col lg:flex-row items-center justify-between gap-8">
            {/* Step 1 */}
            <div className="flex-1">
              <MagicCard 
                className="relative p-8 cursor-pointer shadow-2xl"
                gradientColor="#D9D9D955"
                gradientSize={200}
                gradientOpacity={0.8}
                gradientFrom="#FFA500"  // Amber color
                gradientTo="#FF7F50"    // Coral color
              >
                <div className="absolute -top-6 left-8 bg-amber-500 w-12 h-12 rounded-full flex items-center justify-center text-white text-xl font-bold">
                  1
                </div>
                <div className="flex flex-col items-center text-center gap-4">
                  <UserPlus className="w-12 h-12 text-amber-500" />
                  <h3 className="text-xl font-semibold">Sign-Up</h3>
                  <p className="text-gray-600">
                    Click on the 'Start Free Trial' button above to create your account and begin the registration process
                  </p>
                </div>
              </MagicCard>
            </div>

            {/* Arrow 1 */}
            <ArrowRight className="hidden lg:block w-8 h-8 text-amber-500 flex-shrink-0" />

            {/* Step 2 */}
            <div className="flex-1">
            <MagicCard 
                className="relative p-8 cursor-pointer shadow-2xl"
                gradientColor="#D9D9D955"
                gradientSize={200}
                gradientOpacity={0.8}
                gradientFrom="#FFA500"  // Amber color
                gradientTo="#FF7F50"    // Coral color
              >
                <div className="absolute -top-6 left-8 bg-amber-500 w-12 h-12 rounded-full flex items-center justify-center text-white text-xl font-bold">
                  2
                </div>
                <div className="flex flex-col items-center text-center gap-4">
                  <Calendar className="w-12 h-12 text-amber-500" />
                  <h3 className="text-xl font-semibold">Book Your Sessions</h3>
                  <p className="text-gray-600">
                    Enter your child's details and choose the time slots you want to schedule their personalised lessons for
                  </p>
                </div>
              </MagicCard>
            </div>

            {/* Arrow 2 */}
            <ArrowRight className="hidden lg:block w-8 h-8 text-amber-500 flex-shrink-0" />

            {/* Step 3 */}
            <div className="flex-1">
            <MagicCard 
                className="relative p-8 cursor-pointer shadow-2xl"
                gradientColor="#D9D9D955"
                gradientSize={200}
                gradientOpacity={0.8}
                gradientFrom="#FFA500"  // Amber color
                gradientTo="#FF7F50"    // Coral color
              >
                <div className="absolute -top-6 left-8 bg-amber-500 w-12 h-12 rounded-full flex items-center justify-center text-white text-xl font-bold">
                  3
                </div>
                <div className="flex flex-col items-center text-center gap-4">
                  <GraduationCap className="w-12 h-12 text-amber-500" />
                  <h3 className="text-xl font-semibold">Start Learning!</h3>
                  <p className="text-gray-600">
                  Join your scheduled learning sessions with our expert tutors and track progress with our learning reports
                  </p>
                </div>
              </MagicCard>
            </div>
          </div>
        </div>
      </div>

      <div id="reviews" className="py-10 bg-slate-50">
        <div className="max-w-7xl mx-auto">
          <SparklesText 
            text="What Parents Are Saying"
            className="text-center pb-6"
            colors={{ first: "#FFD700", second: "#FFA500" }}
          />
          {/*<h2 className="text-3xl font-bold text-center mb-12">
            What Parents Are Saying
          </h2>*/}
          
          <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg">
            {/* First row - moving left to right */}
            <Marquee className="py-4 [--duration:25s]" pauseOnHover>
              {firstRow.map((review, index) => (
                <ReviewCard key={index} {...review} />
              ))}
            </Marquee>
            
            {/* Second row - moving right to left */}
            <Marquee className="py-4 [--duration:25s]" pauseOnHover reverse>
              {secondRow.map((review, index) => (
                <ReviewCard key={index} {...review} />
              ))}
            </Marquee>
            
            {/* Gradient Overlays */}
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-slate-50"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-slate-50"></div>
          </div>
        </div>
      </div>

      <div id="pricing" className="py-10 bg-white/0">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-10">
            Learning Plans
          </h2>

          <div className="flex flex-col lg:flex-row items-stretch justify-center gap-8 max-w-5xl mx-auto">
            {/* Single Plan */}
            <div className="flex-1">
              <MagicCard 
                className="relative p-8 cursor-pointer shadow-2xl h-full"
                gradientColor="#D9D9D955"
                gradientSize={200}
                gradientOpacity={0.8}
                gradientFrom="#FFA500"
                gradientTo="#FF7F50"
              >
                <div className="flex flex-col h-full">
                  <h3 className="text-2xl font-bold mb-2">Single Plan</h3>
                  <div className="text-4xl font-bold mb-6">
                    £60<span className="text-lg font-normal text-gray-600">/month</span>
                  </div>
                  
                  <ul className="space-y-4 mb-8 flex-grow">
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Access for one child</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>8 classroom sessions per month</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Personalised, interactive lessons tailored to your child</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Support from our expert learning instructors overseeing your child's learning sessions</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Detailed progress reports on your child's learning journey</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Access to our digital learning assistant Addy who will answer your child's questions and boost their learning</span>
                    </li>
                  </ul>

                  <Button 
                    onClick={() => navigate('/register')}
                    className="w-full bg-gradient-to-r from-amber-500 to-orange-500 text-white py-6 rounded-full text-lg font-medium hover:shadow-lg transition-all duration-300"
                  >
                    Try For Free!
                  </Button>
                </div>
              </MagicCard>
            </div>

            {/* Family Plan */}
            <div className="flex-1">
              <MagicCard 
                className="relative p-8 cursor-pointer shadow-2xl h-full"
                gradientColor="#D9D9D955"
                gradientSize={200}
                gradientOpacity={0.8}
                gradientFrom="#FFA500"
                gradientTo="#FF7F50"
              >
                <div className="flex flex-col h-full">
                <div className="flex items-center justify-between">
                  <h3 className="text-2xl font-bold mb-2">Family Plan</h3>
                  <div className="bg-amber-100 text-amber-800 px-3 py-1 rounded-full text-sm font-medium w-fit">
                    Most Popular
                  </div>
                </div>
                  <div className="text-4xl font-bold mb-6">
                    £90<span className="text-lg font-normal text-gray-600">/month</span>
                  </div>
                  
                  <ul className="space-y-4 mb-8 flex-grow">
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span className="font-medium">Access for up to 4 children</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>8 classroom sessions per child, per month</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Personalised, interactive lessons tailored to each child</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Support from our expert learning instructors overseeing your children's learning sessions</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Detailed progress reports on your children's learning journey</span>
                    </li>
                    <li className="flex items-start gap-3">
                      <Check className="w-5 h-5 text-amber-500 mt-1 flex-shrink-0" />
                      <span>Access to our digital learning assistant Addy who will answer your children's questions and boost their learning</span>
                    </li>
                  </ul>

                  <Button 
                    onClick={() => navigate('/register')}
                    className="w-full bg-gradient-to-r from-amber-500 to-orange-500 text-white py-6 rounded-full text-lg font-medium hover:shadow-lg transition-all duration-300"
                  >
                    Try For Free!
                  </Button>
                </div>
              </MagicCard>
            </div>
          </div>
        </div>
      </div>

      <div id="faqs" className="py-20 bg-white/0">
        <div className="max-w-3xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">
            Frequently Asked Questions
          </h2>

          <Card className="border-2 border-amber-500">
            <CardHeader>
              <CardTitle className="text-center">Common Questions</CardTitle>
            </CardHeader>
            <CardContent>
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1">
                  <AccordionTrigger className="text-left">
                    What year groups do you provide tuition for?
                  </AccordionTrigger>
                  <AccordionContent>
                    We provide Maths lessons for children from years 2 to year 6
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-2">
                  <AccordionTrigger className="text-left">
                    Do you follow the national curriculum?
                  </AccordionTrigger>
                  <AccordionContent>
                    Yes, our lesson content strictly follows the UK Maths National Curriculum, 
                    so will help your child to excel and progress in their school work
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-3">
                  <AccordionTrigger className="text-left">
                    Can my child ask for help when they're stuck?
                  </AccordionTrigger>
                  <AccordionContent>
                    Yes! Our two-layered support system means that children will receive help 
                    and support from both our digital assistant Addy, and from one of our 
                    expert instructors that oversee your child's learning sessions
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem value="item-4">
                  <AccordionTrigger className="text-left">
                    Will I be kept updated on my child's learning?
                  </AccordionTrigger>
                  <AccordionContent>
                    Yes! Our progress reports will keep you updated on your child's learning 
                    progress as they progress through our classroom learning sessions
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;