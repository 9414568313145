import axios from 'axios';
import Cookies from 'js-cookie';
import { get, post, del } from '../utils/api';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email, password) => {

  // i've apparently got some duplication between this login and the login in authContext.js

  try {
    const response = await post(`${API_URL}/auth/login`, { email, password });
    //console.log('Login response:', response);
    if (response.token && response.userData) {
      ///console.log('Received token:', response.token);
      Cookies.set('token', response.token, { expires: 7 });
      localStorage.setItem('user', JSON.stringify(response.userData));
      return { 
        success: true, 
        userData: response.userData
      };
    }
    return { success: false, message: 'Login failed' };
  } catch (error) {
    console.error('Login error:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};

export const register = async (name, email, mobileNumber, password) => {
  try {
    const response = await post(`${API_URL}/auth/register`, { 
      name, 
      email, 
      mobileNumber, 
      password 
    });
    
    //console.log('Registration response:', response); // Debug log

    if (response.token && response.userData) {
      //console.log('Received token:', response.token);
      Cookies.set('token', response.token, { expires: 7 });
      localStorage.setItem('user', JSON.stringify(response.userData));
      return { 
        success: true, 
        userData: response.userData
      };
    }
    return { 
      success: false, 
      message: response.message || 'Registration failed' 
    };
  } catch (error) {
    console.error('Registration error:', error);
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};

export const logout = () => {
  Cookies.remove('token');
};

export const verifyToken = async (token) => {
  try {
    //console.log('Verifying token:', token); // Log the token being sent
    const response = await axios.get(`${API_URL}/auth/verify`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    //console.log('Verification response:', response.data); // Log the response
    return response.data;
  } catch (error) {
    console.error('Token verification error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await post(`${API_URL}/auth/forgot-password`, { email });
    return { success: true, message: response.message };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await post(`${API_URL}/auth/reset-password`, {
      token,
      newPassword
    });
    return { success: true, message: response.message };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.message || 'An error occurred' 
    };
  }
};
