import './Tile.css';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
import Username from '../Username/Username';
import { Ear, EarOff } from 'lucide-react';

export default function Tile({ id, isScreenShare, TutorWatchMode, StudentWatchMode, isLocal, isAlone, canHearTutor, onHearingToggle, isLargeTile}) {
  const videoState = useVideoTrack(id);

  let containerCssClasses = isLargeTile ? 'large-tile' :
    (TutorWatchMode ? 'tutor-watch-video' : 
    (StudentWatchMode ? 'student-watch-video' : 'tile-video'));

  if (isLocal && !isLargeTile) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses += ' no-video';
  }

  return (
    <div className={containerCssClasses}>
      <DailyVideo automirror sessionId={id} fit="contain" type={isScreenShare ? 'screenVideo' : 'video'} />
      {!isScreenShare && <Username id={id} isLocal={isLocal} StudentWatchModeUserName={StudentWatchMode} />}
                  {/* Modified ear icon div */}
                { onHearingToggle && ( <div 
                className="absolute bottom-0 right-0 p-2 cursor-pointer" 
                onClick={onHearingToggle}
            >
                {canHearTutor ? <Ear color="#ffffff"/> : <EarOff color="#f63135"/>}
            </div> )}
    </div>
  );
}