import React from 'react';
import { ONBOARDING_STEPS } from '../../../utils/constants/constants';

const steps = Object.values(ONBOARDING_STEPS);

function StepIndicator({ currentStep }) {
  const currentStepIndex = steps.indexOf(currentStep);

  return (
    <div className="w-full bg-white border-b border-gray-200">
      <div className="max-w-4xl mx-auto px-4 py-6">
        <div className="flex items-center justify-between">
          {steps.map((step, index) => (
            <React.Fragment key={step}>
              {/* Step circle */}
              <div className="flex flex-col items-center">
                <div className={`
                  h-8 w-8 rounded-full flex items-center justify-center
                  ${index <= currentStepIndex ? 'bg-violet-600 text-white' : 'bg-gray-200 text-gray-600'}
                `}>
                  {index + 1}
                </div>
                <span className="text-xs mt-2 text-gray-500">
                  {step.charAt(0) + step.slice(1).toLowerCase()}
                </span>
              </div>

              {/* Connecting line */}
              {index < steps.length - 1 && (
                <div className={`h-1 w-full max-w-[100px] ${
                  index < currentStepIndex ? 'bg-violet-600' : 'bg-gray-200'
                }`} />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StepIndicator;