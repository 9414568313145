import TutorHomePageV2 from './TutorHomePageV2';
import TutorSingleQuestionPage from './Quiz/TutorSingleQuestionPage/TutorSingleQuestionPage';
import { useEffect, useState } from 'react';
import Tile from '../DailyVideoCalls/Tile/Tile';
import { Ear, EarOff } from 'lucide-react';
import './TutorLearningSession.css';

function TutorLearningSession({ studentState, student, canHearTutor, onHearingToggle }) {
    const [currentView, setCurrentView] = useState(null);
    
   /* useEffect(() => {
        console.log('Student State in TutorLearningSession:', studentState);
    }, [studentState]);*/

    useEffect(() => {
        setCurrentView(studentState?.currentView);
    }, [studentState?.currentView]);

    return (
        <div className='tutor-session-tile'>
            {/* Content based on currentView */}
            {currentView === 'lesson' && (
                <TutorHomePageV2 studentState={studentState} />
            )}
            
            {currentView === 'quiz' && studentState?.quizSubmission && (
                <TutorSingleQuestionPage 
                    quizQuestion={
                        studentState?.quizSubmission?.questionAttempts
                            ?.filter(attempt => attempt.QuestionStatus === "not attempted")
                            ?.[0]
                            ?.LessonQuestionId ?? null
                    }
                    ButtonsAreClickable={false}
                    onAnswerSelect={() => {}}
                />
            )}

            {/* Tile component rendered once, outside of conditional blocks */}
            <div key={student.session_id} className="absolute bottom-0 right-0">
                <Tile 
                    key={student.session_id} 
                    id={student.session_id} 
                    StudentWatchMode
                />
            </div>

            {/* Modified ear icon div */}
            <div 
                className="absolute bottom-0 left-0 p-2 cursor-pointer" 
                onClick={onHearingToggle}
            >
                {canHearTutor ? <Ear /> : <EarOff color="#f63135"/>}
            </div>
        </div>
    );
}

export default TutorLearningSession;