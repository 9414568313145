import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { get } from '../utils/api';
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from './shadcn/accordion';
import { Button } from './shadcn/button';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={handleOutsideClick}>
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <Button onClick={onClose} className="mb-4">Close</Button>
        {children}
      </div>
    </div>
  );
};

const ChildTestResults = () => {
  const { loggedInChild } = useAuth();
  const [quizResults, setQuizResults] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (loggedInChild) {
      fetchQuizResults();
    }
  }, [loggedInChild]);

  const fetchQuizResults = async () => {
    try {
      const results = await get(`/quiz/completed-submissions/${loggedInChild._id}`, true);
      const groupedResults = groupResultsByLesson(results);
      setQuizResults(groupedResults);
    } catch (error) {
      console.error('Error fetching quiz results:', error);
    }
  };

  const groupResultsByLesson = (results) => {
    const grouped = results.reduce((acc, submission) => {
      const lessonId = submission.lessonId._id;
      if (!acc[lessonId]) {
        acc[lessonId] = {
          lesson: submission.lessonId,
          submissions: []
        };
      }
      acc[lessonId].submissions.push(submission);
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const handleSeeSubmission = async (submissionId) => {
    try {
      const submissionDetails = await get(`/quiz/quiz-submissions/${submissionId}`, true);
      setSelectedSubmission(submissionDetails);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching submission details:', error);
    }
  };

  const getDisplayedAnswer = (attempt) => {
    if (['Blank-Box-Question', 'Drag-And-Drop-Question'].includes(attempt.LessonQuestionId.questionType)) {
      return (
        <div className="relative mb-4">
          <div className="relative inline-block">
            <img 
              src={attempt.LessonQuestionId.image} 
              alt="Question" 
              className="max-w-full h-auto"
            />
            {Object.entries(attempt.BoxTypeAnswers || {}).map(([boxId, answer]) => {
              const box = attempt.LessonQuestionId.questionType === 'Blank-Box-Question'
                ? attempt.LessonQuestionId.blankBoxes.find(b => b.id === boxId)
                : attempt.LessonQuestionId.dragBoxes.find(b => b.id === boxId);

              if (!box) return null;

              return (
                <div
                  key={boxId}
                  className={`absolute border-2 ${
                    attempt.QuestionStatus === 'correct'
                      ? 'border-green-500 bg-green-100/80'
                      : 'border-red-500 bg-red-100/80'
                  }`}
                  style={{
                    left: `${box.x}%`,
                    top: `${box.y}%`,
                    width: `${box.width}%`,
                    height: `${box.height}%`,
                  }}
                >
                  <div className="w-full h-full flex items-center justify-center text-sm p-1">
                    {answer}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (attempt.LessonQuestionId.questionType === 'Free-Text-Question') {
      return attempt.chosenAnswer;
    } else {
      const chosenAnswerObj = attempt.LessonQuestionId.answers.find(
        answer => answer.id === attempt.chosenAnswer
      );
      return chosenAnswerObj ? chosenAnswerObj.text : 'Answer not found';
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">
        {loggedInChild 
          ? `${loggedInChild.name}'s Learning Progress` 
          : "Login as a child to see their learning progress"}
      </h1>
      
      {loggedInChild ? (
        quizResults.length > 0 ? (
          <Accordion type="single" collapsible className="w-full">
            {quizResults.map((lessonResult, index) => (
              <AccordionItem value={`item-${index}`} key={index}>
                <AccordionTrigger>Lesson: {lessonResult.lesson.title}</AccordionTrigger>
                <AccordionContent>
                  {lessonResult.submissions.map((submission, subIndex) => (
                    <div key={subIndex} className="mb-4 p-4 border rounded flex justify-between items-center">
                      <div>
                        <p>Attempt Number: {submission.AttemptNumber}</p>
                        <p>Score: {(submission.score * 100).toFixed(2)}%</p>
                      </div>
                      <Button onClick={() => handleSeeSubmission(submission._id)}>
                        See Quiz Submission
                      </Button>
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <p className="text-lg">{loggedInChild.name}'s progess will appear here when they have completed Lessons and Quizzes</p>
        )
      ) : (
        <div>
          <p>Please go to the Children Dashboard to login as a child.</p>
        </div>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedSubmission && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Quiz Submission Details</h2>
            {selectedSubmission.questionAttempts.map((attempt, index) => (
              <div 
                key={index} 
                className={`mb-4 p-4 border rounded ${
                  attempt.QuestionStatus === 'correct' ? 'bg-green-100' : 'bg-red-100'
                }`}
              >
                <p className="font-bold mb-2">Question {index + 1}:</p>
                <p className="mb-2"><strong>Question:</strong> {attempt.LessonQuestionId.question}</p>
                <div className="mb-2">
                  <strong>Chosen Answer:</strong> {getDisplayedAnswer(attempt)}
                </div>
                <p><strong>Result:</strong> {attempt.QuestionStatus === 'correct' ? 'Correct' : 'Incorrect'}</p>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ChildTestResults;
