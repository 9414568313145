// utils/api.js

import axios from 'axios';
import Cookies from 'js-cookie'; // Assuming this is the import for Cookies

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeaders = (requireAuth) => {
  let headers = {
    'Content-Type': 'application/json',
  };
  if (requireAuth) {
    const token = Cookies.get('token');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return headers;
};

export const get = async (url, requireAuth = false) => {
  try {
    const response = await instance.get(url, { headers: getHeaders(requireAuth) });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const post = async (url, data, requireAuth = false) => {
  try {
    let config = {};
    
    if (requireAuth) {
      const token = Cookies.get('token');
      if (token) {
        config.headers = {
          'Authorization': `Bearer ${token}`
        };
      }
    }

    //console.log('Request URL:', url);
    //console.log('Request Data:', data);

    const response = await instance.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    handleError(error);
    throw error;
  }
};

export const put = async (url, data, requireAuth = false) => {
  try {
    let config = {};
    
    if (requireAuth) {
      const token = Cookies.get('token');
      if (token) {
        config.headers = {
          'Authorization': `Bearer ${token}`
        };
      }
    }

    //console.log('Request URL:', url);
    //console.log('Request Data:', data);
    //console.log('Request Headers:', config.headers);

    const response = await instance.put(url, data, config);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    handleError(error);
    throw error;
  }
};

export const del = async (url, requireAuth = false) => {
  try {
    const response = await instance.delete(url, { headers: getHeaders(requireAuth) });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    handleError(error);
    throw error;
  }
};

export const patch = async (url, data, requireAuth = false) => {
  try {
    let config = {};
    
    if (requireAuth) {
      const token = Cookies.get('token');
      if (token) {
        config.headers = {
          'Authorization': `Bearer ${token}`
        };
      }
    }

    //console.log('Request URL:', url);
    //console.log('Request Data:', data);
    //console.log('Request Headers:', config.headers);

    const response = await instance.patch(url, data, config);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    handleError(error);
    throw error;
  }
};

// You can add other methods (put, patch, delete) here as needed

const handleError = (error) => {
  if (error.response) {
    console.error("Server responded with error:", error.response.data);
    console.error("Status code:", error.response.status);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up request:", error.message);
  }
};