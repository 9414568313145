import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter
} from '@/components/shadcn/card';
import { Button } from '@/components/shadcn/button';
import { cn } from "@/lib/utils";

function SubscriptionPlan({ onComplete, childCount, currentPlan, childDetails }) {
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [showWarning, setShowWarning] = useState(false);
  const isMultipleChildren = childCount > 1;

  const handlePlanSelection = (plan) => {
    if (plan === 'SINGLE' && childDetails?.length > 1) {
      setShowWarning(true);
    }
    setSelectedPlan(plan);
  };

  const handleContinue = () => {
    if (selectedPlan) {
      onComplete({
        subscriptionPlan: selectedPlan
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-6">
      <div className="w-full max-w-5xl mb-8">
        <div className="bg-green-50 border-2 border-green-500 rounded-xl p-6 shadow-lg">
          <h2 className="text-2xl font-bold text-green-700 text-center mb-2">
            7-Day Free Trial
          </h2>
          <p className="text-lg text-green-600 text-center">
            Try Nova Learning completely free for 7 days. <span className="font-bold">No payment will be taken until your trial ends</span>.
          </p>
        </div>
      </div>

      {isMultipleChildren && (
        <div className="mb-8 text-center">
          <p className="text-lg font-medium text-indigo-600">
            Based on your {childCount} children, we recommend the family plan!
          </p>
        </div>
      )}
      {!isMultipleChildren && (
        <div className="mb-8 text-center">
          <p className="text-lg font-medium text-indigo-600">
            Based on your single child, we recommend the Single plan!
          </p>
        </div>
      )}

      {showWarning && (
        <div className="mb-8 p-4 bg-yellow-50 border border-yellow-400 rounded-md">
          <p className="text-yellow-700">
            Switching to the Single Child Plan will remove any additional children from your account.
            Only the first child's details will be kept.
          </p>
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-8 max-w-5xl w-full mb-8">
        {/* Single Child Plan */}
        <Card className={cn(
          "relative transition-all duration-200",
          selectedPlan === 'SINGLE' && "ring-2 ring-green-600 shadow-lg",
          !selectedPlan && !isMultipleChildren && "ring-2 ring-indigo-600 shadow-lg scale-105"
        )}>
          <div className="absolute -top-4 right-4 px-4 py-1 bg-green-500 text-white rounded-full text-sm font-medium">
            7 Days Free
          </div>
          {selectedPlan === 'SINGLE' && (
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-4 py-1 rounded-full text-sm font-medium bg-green-600 text-white">
              Currently Selected
            </div>
          )}
          {!selectedPlan && !isMultipleChildren && (
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-4 py-1 rounded-full text-sm font-medium bg-indigo-600 text-white">
              Recommended
            </div>
          )}
          <CardHeader>
            <CardTitle className="text-2xl">Single Child Plan</CardTitle>
            <CardDescription className="text-lg">Perfect for one child</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="mb-6">
              <span className="text-4xl font-bold">£60</span>
              <span className="text-gray-600 text-lg">/month</span>
              <p className="text-sm text-green-600 font-medium mt-2">
                Start with 7 days free, cancel anytime
              </p>
            </div>
            <ul className="mb-8 space-y-3">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Access for one child
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                8 classroom sessions per month
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Personalised, interactive lessons tailored to your child
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Support from our expert learning instructors overseeing your child's learning sessions
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Detailed progress reports on your child's learning journey
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Access to our digital learning assistant Addy who will answer your child's questions and boost their learning
              </li>
            </ul>
          </CardContent>
          <CardFooter>
            <Button 
              onClick={() => handlePlanSelection('SINGLE')}
              className={cn(
                "w-full py-6 text-lg",
                selectedPlan === 'SINGLE'
                  ? "bg-green-600 hover:bg-green-700"
                  : !isMultipleChildren 
                    ? "bg-indigo-600 hover:bg-indigo-700" 
                    : "bg-gray-600 hover:bg-gray-700"
              )}
            >
              {selectedPlan === 'SINGLE' ? 'Selected' : 'Select Single Plan'}
            </Button>
          </CardFooter>
        </Card>

        {/* Family Plan */}
        <Card className={cn(
          "relative transition-all duration-200",
          selectedPlan === 'FAMILY' && "ring-2 ring-green-600 shadow-lg",
          !selectedPlan && isMultipleChildren && "ring-2 ring-indigo-600 shadow-lg scale-105"
        )}>
          <div className="absolute -top-4 right-4 px-4 py-1 bg-green-500 text-white rounded-full text-sm font-medium">
            7 Days Free
          </div>
          {selectedPlan === 'FAMILY' && (
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-4 py-1 rounded-full text-sm font-medium bg-green-600 text-white">
              Currently Selected
            </div>
          )}
          {!selectedPlan && isMultipleChildren && (
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 px-4 py-1 rounded-full text-sm font-medium bg-indigo-600 text-white">
              Recommended
            </div>
          )}
          <CardHeader>
            <CardTitle className="text-2xl">Family Plan</CardTitle>
            <CardDescription className="text-lg">Perfect for multiple children</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="mb-6">
              <span className="text-4xl font-bold">£90</span>
              <span className="text-gray-600 text-lg">/month</span>
              <p className="text-sm text-green-600 font-medium mt-2">
                Start with 7 days free, cancel anytime
              </p>
            </div>
            <ul className="mb-8 space-y-3">
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Access for up to 4 children
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                8 classroom sessions per child, per month
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Personalised, interactive lessons tailored to each child
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Support from our expert learning instructors overseeing your children's learning sessions
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Detailed progress reports on your child's learning journey
              </li>
              <li className="flex items-center">
                <span className="text-green-500 mr-2">✓</span>
                Access to our digital learning assistant Addy who will answer your children's questions and boost their learning
              </li>
            </ul>
          </CardContent>
          <CardFooter>
            <Button 
              onClick={() => handlePlanSelection('FAMILY')}
              className={cn(
                "w-full py-6 text-lg",
                selectedPlan === 'FAMILY'
                  ? "bg-green-600 hover:bg-green-700"
                  : isMultipleChildren 
                    ? "bg-indigo-600 hover:bg-indigo-700" 
                    : "bg-gray-600 hover:bg-gray-700"
              )}
            >
              {selectedPlan === 'FAMILY' ? 'Selected' : 'Select Family Plan'}
            </Button>
          </CardFooter>
        </Card>
      </div>

      {/* Continue Button */}
      <div className="w-full max-w-5xl">
        <Button 
          onClick={handleContinue}
          disabled={!selectedPlan}
          className={cn(
            "w-full py-6 text-lg",
            "bg-indigo-600 hover:bg-indigo-700",
            "disabled:bg-gray-300 disabled:cursor-not-allowed"
          )}
        >
          Continue with {selectedPlan ? `${selectedPlan.charAt(0) + selectedPlan.slice(1).toLowerCase()} Plan` : 'Selected Plan'}
          {/*Start 7-Day Free Trial with {selectedPlan ? `${selectedPlan.charAt(0) + selectedPlan.slice(1).toLowerCase()} Plan` : 'Selected Plan'}*/}
        </Button>
        <p className="text-center text-sm text-gray-600 mt-2">
          Your free trial will begin when you have completed the sign-up process.
        </p>
      </div>
    </div>
  );
}

export default SubscriptionPlan;