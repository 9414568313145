import './Username.css';
import { useParticipantProperty } from '@daily-co/daily-react';

export default function Username({ id, isLocal, StudentWatchModeUserName }) {
  const username = useParticipantProperty(id, 'user_name');
  const userData = useParticipantProperty(id, 'userData');
  const displayName = userData?.displayName || username;

  const userNameCss = StudentWatchModeUserName ? 'student-watch-username' : 'username';

  return (
    <div className={userNameCss}>
      {displayName} {isLocal && '(you)'}
    </div>
  );
}